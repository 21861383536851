import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ModalContent from '@components/Modals/components/ModalContent';
import { useContext } from 'react';
import Choice from '@components/UI/Choice/Choice';
import { AddGameContext } from '../context/AddGameProvider';
import isRegion from '../helpers/isRegion';
import useRevertAddGameLoadingStateOnUnmount from '../hooks/useRevertAddGameLoadingStateOnUnmount';
import useTrackEvent from '@hooks/useTrackEvent';
export const ContentLOLRegions = ({ onRegionSelected, stepName }) => {
    const { connectPlatform, connectGameRegion } = useContext(AddGameContext);
    const trackEvent = useTrackEvent();
    const renderedOptions = (connectPlatform === null || connectPlatform === void 0 ? void 0 : connectPlatform.regions)
        ? connectPlatform.regions.filter((region) => region.enabled === true).map(({ name, id }) => ({
            label: name,
            value: id,
            id,
            paletteName: 'lol',
        }))
        : [];
    const getSelectedRegion = (selectedRegionId) => {
        if (connectPlatform === null || connectPlatform === void 0 ? void 0 : connectPlatform.regions) {
            const selectedRegion = connectPlatform.regions.filter((region) => {
                return region.id === selectedRegionId;
            });
            return selectedRegion.length > 0 ? selectedRegion[0] : null;
        }
        return null;
    };
    const handleSelect = (selectedRegionId) => {
        const selectedRegion = getSelectedRegion(selectedRegionId);
        if (selectedRegion && onRegionSelected) {
            onRegionSelected(selectedRegion);
            trackEvent({
                type: 'game_linking',
                data: {
                    eventName: 'game linking',
                    label: selectedRegion.name,
                    moduleName: 'Button',
                    position: 0,
                },
            });
        }
    };
    useRevertAddGameLoadingStateOnUnmount();
    return (_jsxs(ModalContent, { stepName: stepName, verticalAlign: "top", children: [_jsx("h3", { id: "add-game-lol-select-region", children: "Select your account region" }), _jsx(Choice, { selectLabel: "Region", choices: renderedOptions, onSelect: handleSelect, initialSelection: isRegion(connectGameRegion) ? connectGameRegion.id : '', defaultPaletteName: "lol", ariaLabelledBy: "add-game-lol-select-region" })] }));
};
export default ContentLOLRegions;
